import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../../components/layoutSurpriseCustom"
import BgImg from "../../images/plan-phoenix.jpg"
import SEO from "../../components/seo"
import PageHeaders from "../../components/pageHeaders"

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Plan a Visit"
    SecondText="We are saving a seat for you."
  >
    <SEO title="Plan a Visit - Surprise" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Visit Cross Church Surprise
    </PageHeaders>
    <iframe
      src="https://cccares.formstack.com/forms/planavisit"
      title="Plan a Visit"
      width="100%"
      height="1400px"
      frameBorder="0"
    ></iframe>
  </Layout>
)

export default IndexPage
